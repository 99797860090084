<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center align-items-center">
        <amplify-authenticator>
          <amplify-sign-in
              slot="sign-in"
              :formFields.prop="loginFormFields"
              :hide-sign-up="true"
          ></amplify-sign-in>
          <amplify-forgot-password
              slot="forgot-password"
              :formFields.prop="resetFormFields"
          ></amplify-forgot-password>
        </amplify-authenticator>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginFormFields: [
        {
          type: 'username',
          label: 'Customer ID',
          placeholder: 'Enter your Customer ID',
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          label: 'Admin Key or Password',
          placeholder: 'Enter your Admin Key or Password',
          inputProps: { required: true, autocomplete: 'new-password' },
        }
      ],
      resetFormFields: [
        {
          type: 'username',
          label: 'Customer ID',
          placeholder: 'Enter your Customer ID',
          inputProps: { required: true, autocomplete: 'username' },
        },
      ],
    }
  }
}
</script>
<style lang="scss">
amplify-authenticator {
  amplify-sign-in {

    &:before {
      content: '';
      display: block;
      width: 260px;
      height: 74px;
      background: url('/logo-dark.svg') no-repeat center center / contain;
      margin: 0 auto 30px;
    }
  }
}
.logo {
  max-width: 260px;
  padding: 20px 0;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
